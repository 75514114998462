// Our modules / classes
import Navigation from "./modules/Navigation"
import Scrollspy from "./modules/Scrollspy"
import ActiveDate from "./modules/ActiveDate"
import DisableBtn from "./modules/DisableBtn"
import Animation from "./modules/Animation"
import Carousel from "./modules/Carousel"
import Wcag from "./modules/Wcag"
// import Galery from "./modules/Galery"


// Instantiate a new object using our modules/classes
const navigation = new Navigation();
const scrollspy = new Scrollspy();
const activeDate = new ActiveDate();
const disableBtn = new DisableBtn();
const animation = new Animation();
const carousel = new Carousel();
const wcag = new Wcag();
// const galery = new Galery();