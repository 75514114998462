class ActiveDate {
    constructor() {

        function setActiveDate() {
            let currentDate = new Date();
            const stageOne = new Date('2024-10-15');
            const stageTwo = new Date('2025-01-14');
            const stageThree = new Date('2025-03-25');
        
            let currentDateCompare = currentDate.valueOf();
            let stageOneCompare = stageOne.valueOf();
            let stageTwoCompare = stageTwo.valueOf();
            let stageThreeCompare = stageThree.valueOf();
        
            const stageOneElem = document.querySelector('[data-stage="1"]');
            const stageTwoElem = document.querySelector('[data-stage="2"]');
            const stageThreeElem = document.querySelector('[data-stage="3"]');
        
            if (currentDateCompare <= stageOneCompare) {
                stageOneElem.classList.add('active');
            } else if (currentDateCompare > stageOneCompare && currentDateCompare <= stageTwoCompare) {
                stageTwoElem.classList.add('active');
            } else if (currentDateCompare > stageTwoCompare && currentDateCompare <= stageThreeCompare) {
                stageThreeElem.classList.add('active');
            }
        }

        setActiveDate();

    }
}


export default ActiveDate